import React from "react";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { useState, useEffect } from "react";
import JsonData from "../data/data.json";
import { Link } from "react-router-dom";


// the one has been import in the App.jsx
const AboutUs = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <div class="page-button">
        <div class="container">
          <Link to="/contact-us" class="page-button__anchor">
              Contact Us
          </Link> 
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
