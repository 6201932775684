import Home from "./pages/Home";
import SmoothScroll from "smooth-scroll";
import { Router, Switch, Route } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import Footer from "./components/Footer"
import Navbar from "./components/Navbar/Navbar"
import ContactUs from './pages/ContactUs'
import SafetyTools from "./pages/SafetyTools";
import ProjectManagement from './pages/Service_Pages/ProjectManagement'
import Design from './pages/Service_Pages/Design'
import RadioCompliance from './pages/Service_Pages/RadioCompliance'
import PropertyPlanning from './pages/Service_Pages/PropertyPlanning'
import NetworkConstruction from './pages/Service_Pages/NetworkConstruction'
import InBuildingSystems from './pages/Service_Pages/InBuildingSystems'
import RadioFrequencySystems from './pages/Service_Pages/RadioFrequencySystems'
import Career from './pages/Career'
import OperationsAndMaintenance from './pages/Service_Pages/OperationsAndMaintenance'
import FieldServices from './pages/Service_Pages/FieldServices'
import EmeCompliance from './pages/Service_Pages/EmeCompliance'
import TransmissionUpgrade from './pages/Service_Pages/TransmissionUpgrade'
import Saed from './pages/Service_Pages/Saed'
import { ToastProvider } from 'react-toast-notifications';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <ToastProvider>
        <Navbar />
        <Switch>
          <Route exact path="/" exact component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/contact-us" component={ContactUs} />    
          <Route exact path="/safetytools" component={SafetyTools} />
          <Route exact path="/saed" component={Saed} />
          <Route exact path="/projectmanagement" component={ProjectManagement} />
          <Route exact path="/design" component={Design} />
          <Route exact path="/radioCompliance" component={RadioCompliance} />
          <Route exact path="/propertyPlanning" component={PropertyPlanning} />
          <Route exact path="/networkConstruction" component={NetworkConstruction} />
          <Route exact path="/inBuildingSystems" component={InBuildingSystems} />
          <Route exact path="/radioFrequencySystems" component={RadioFrequencySystems} />
          <Route exact path="/operationsAndMaintenance" component={OperationsAndMaintenance} />
          <Route exact path="/fieldServices" component={FieldServices} />
          <Route exact path="/emeCompliance" component={EmeCompliance} />
          <Route exact path="/transmissionUpgrade" component={TransmissionUpgrade} />
        </Switch>
        <Footer />
    </ToastProvider>
  );
};

export default App;
