import React from "react";
import "./ProjectContent.css";

export const ProjectContent = ({picture, parag1,list, parag2,subtitle }) => {
  // const list1 = list.split(',');

  return (
    <div className="project">
      <div className="container">
        <div className="container-row">
          <div className="container-box">
            <div className="project-content-picture">
              <img src='img/project/DAS-installations.png'  alt="" className='img-responsive' width="10%" height="30px"/>         
            </div>
            
            <div className="project-content-text">
              <h1>Work Health & Safety</h1>

              <p>
              During pre-start survey, all potential hazards will be raised out and recorded into our WHS management system, all onsite workers will be notified and take caution during site build.
              </p>
            </div>
          </div>

          <div className="container-box container-box-bottom">
            <div className="project-content-picture">
              <img src='img/project/Site-Surveys.png'  alt="" className='img-responsive' width="10%" height="30px"/>        
            </div>

            <div className="project-content-text">
              <h1>Pre-start Surveys</h1>

              <p>
              Our pre-start surveys will identify and capture all critical information and discrepancy required for design modification, material call off and installation feasibility during project deployment.
              </p>
            </div>
          </div>
        </div>

        <div className="container-row">
          <div className="container-box">
            <div className="project-content-picture">
              <img src='img/project/Network-testing.png'  alt="" className='img-responsive' width="10%" height="30px"/>   
            </div>

            <div className="project-content-text">
              <h1>Acceptance Improvement</h1>

              <p>
              After sites are integrated and tested successfully, we will submit Handover Pack as first priority. All of handover pack will be internally reviewed before submission to our clients to achieve first-time right.
              </p>
            </div>
          </div>

          <div className="container-box container-box-bottom">
            <div className="project-content-picture">
              <img src='img/project/Network-Design.png'  alt="" className='img-responsive' width="10%" height="30px"/>
            </div>

            <div className="project-content-text">
              <h1>Deployment Monitoring</h1>
              
              <p>
              Site build progress are closely monitored and updated to our customers on a daily basis, whenever issue comes up, we always actively investigate fault and escalate to customer with our solutions.
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
  );
};
