import React from 'react'
import { SubHeader } from "../../components/SubHeader";
import { ServiceContent } from "../../components/ServiceContent";
import about from '../../images/transmission upgrade.png'
import { Link } from "react-router-dom";

function TransmissionUpgrade() {
    return (
        <div>
             <SubHeader title={"Transmission Upgrade"} />

            <ServiceContent parag1={'With experience working on multiple transmission projects, Buffalo Innovation are building long-term relationship with our clients. We are committed to deliver large volume of transmission upgrade sites within timeline. We are proficient in (but not limited) as below:'} 
                            list = {[
                                'Nokia LTE/MPLS router installation and commissioning',
                                'Ericsson SIU Upgrade',
                                'Ericsson Site Router installation and integration',
                                'Huawei ATN 910 Installation and commissioning',
                                'Huawei NTU Cutover',
                                'Huawei OSN installation and commissioning'
                            ]}
                            picture={about}
            />

            <div class="page-button">
                    <div class="container">
                            <Link to='/contact-us' class="page-button__anchor">Contact Us</Link>
                    </div>
                </div>
            </div>
    )
}

export default TransmissionUpgrade
