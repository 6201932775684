import React from 'react'
import { SubHeader } from "../../components/SubHeader";
import { ServiceContent } from "../../components/ServiceContent";
import about from '../../images/InBuildingCoverage2.jpg'
import { Link } from "react-router-dom";

function InBuildingSystems() {
    return (
        <div>
             <SubHeader title={"In-Building Coverage"} />

            <ServiceContent parag1={'As for In–Building Coverage projects, we are experienced extensively and expert at deploying Distribution Antenna System including cabling in all scenarios, especially with complicated installation constraint.'} 
                            list = {[
                                'IBC BTS installation and commissioning',
                                'DAS installation and integration',
                                'HFC MDU system installation, commissioning, testing (business complex), Coaxial CX50, CX75, RG6, RG11 installation and termination',
                                'PIM testing, walk testing and sweep testing'
                            ]}
                            parag2= {''}
                            subtitle={''} 
                            picture={about}
            />

            <div class="page-button">
			     <div class="container">
					 {/* <a href="/contact-us/" class="page-button__anchor">
						Contact us	
                        </a> */}
                    <Link to='/contact-us' class="page-button__anchor">Contact Us</Link>
				</div>
		    </div>

        </div>
    )
}

export default InBuildingSystems
