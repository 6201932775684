import React from 'react'
import { SubHeader } from "../components/SubHeader";
import { ServiceContent } from "../components/ServiceContent";
import about from '../images/career.jpg'
import { Link } from "react-router-dom";

function Career() {
    return (
        <div>
            <SubHeader title={"Career"} />

            <ServiceContent parag1={'Positions we are always hiring for:'} 
                    list = {['Project Managers','Project Engineers','Telecommunications Network Engineer', 'Electricians','Telecommunication Riggers','Telecommunication Technicians','Site Access Coordinator','Document Controller']}
                    parag2= {''}
                    subtitle={'Join Our Professional Team Working on Telecommunications Projects across Australia'} 
                    picture={about}/>

            <div class="page-button">
			     <div class="container">
					 {/* <a href="/contact-us/" class="page-button__anchor">
						Contact us	
                        </a> */}
                        <Link to='/contact-us' class="page-button__anchor">Contact Us</Link>
				</div>
		    </div>
        </div>
    )
}

export default Career
