import React from "react";
import HeroSection from "../components/HeroSection";
import picture from "../images/bgi.jpg";
import AutoPlay from "../components/AutoPlay";
function Home() {
  return (
    <div>
      <div className="home-hero-container">
        <HeroSection bgImg={picture} />
      </div>
      <AutoPlay />
    </div>
  );
}

export default Home;
