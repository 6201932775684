import React from 'react'
import ContactDetail from '../components/ContactDetail'
import ContactForm from '../components/ContactForm'

function ContactUs() {
    return (
        <div className = 'contact-us-container'>
           <ContactDetail/>
           <ContactForm />
        </div>
    )
}

export default ContactUs
