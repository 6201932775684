import React from "react";
import "./HeroSection.css";
import "../App.css";
import { Link } from "react-router-dom";

function HeroSection(props) {
  return (
    <div
      className="hero-container"
      style={{ backgroundImage: `url(${props.bgImg})`, backgroundSize: '100% 100%' }}
    >
      {/* <video src="/videos/video-2.mp4" autoPlay loop muted></video> */}
      <div className="header-container">
        <h2>WELCOME TO BUFFALO INNOVATION</h2>
        <p>
          Established and operated with Qualified Telecommunications
          Professionals.
        </p>
        <div className="button-group">
          <Link to="/contact-us" className="header-button">
            Talk to our experts
          </Link>
          <Link  to="/about-us" className="header-button">
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
