import React from 'react'
import { SubHeader } from "../../components/SubHeader";
import { ServiceContent } from "../../components/ServiceContent";
import about from '../../images/Microwave deployment.jpg'
import { Link } from "react-router-dom";

function RadioFrequencySystems() {
    return (
        <div>
            <SubHeader title={"Microwave Deployment"} />

            <ServiceContent parag1={'Our field delivery team has sufficient experience and qualified tech/riggers to deploy Ceragon and NEC Microwave products on multiple carriers including:'} 
                            list = {[
                                'Line of Sight Survey',
                                'Existing IDUs / ODUs / Dishes de-commissioning',
                                'New IDU installation and commissioning',
                                'New ODU installation and plumbing',
                                'New Parabolic Antenna installation and panning',
                                'Feeder installation and testing']}
                            picture={about}
            />

            <div class="page-button">
			     <div class="container">
					 {/* <a href="/contact-us/" class="page-button__anchor">
						Contact us	
                        </a> */}
                    <Link to='/contact-us' class="page-button__anchor">Contact Us</Link>
				</div>
		    </div>
        </div>
    )
}

export default RadioFrequencySystems
