import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar2.css";
import logo from "../../images/logo.png";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []); 

  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 80 ) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <nav className={navbar ? "navbar active" : "navbar"}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img className="logo-picture" src={logo} alt="Logo" />
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/about-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
          </li>

          <li className="nav-item">
            <div className="dropdown" onClick={closeMobileMenu}>
              <button className="dropbtn nav-links">
                Capabilities <i class="fa fa-caret-down"></i>
              </button>

              <div className="dropdown-content">
                <p>
                  <Link
                    className="link"
                    to="/projectManagement"
                    style={{ fontSize: "18px", color: "white" }}
                  >
                    Project Management
                  </Link>
                </p>

                <p>
                  <Link
                    className="link"
                    to="/networkConstruction"
                    style={{ fontSize: "18px", color: "white" }}
                  >
                    Mobile Network Construction
                  </Link>
                </p>

                <p>
                  <Link
                    className="link"
                    to="/inBuildingSystems"
                    style={{ fontSize: "18px", color: "white" }}
                  >
                    In-building Coverage
                  </Link>
                </p>

                <p>
                  <Link
                    className="link"
                    to="/radioFrequencySystems"
                    style={{ fontSize: "18px", color: "white" }}
                  >
                    Microwave Deployment
                  </Link>
                </p>

                <p>
                  <Link
                    className="link"
                    to="/transmissionUpgrade"
                    style={{ fontSize: "18px", color: "white" }}
                  >
                    Transmission Upgrade
                  </Link>
                </p>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <Link to="/career" className="nav-links" onClick={closeMobileMenu}>
              Career
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/contact-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
