import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import {Icon} from "semantic-ui-react";
import ExampleDoc from '../doc/Environmental_strategy_and_objective.pdf';


function Footer() {
    return (
        <div className='footer-container'>
            <div class="container">
                <div class="grid">
                    <div class="grid__column  grid__column--l-3">
                        <section id="nav_menu-2" class="widget widget_nav_menu">
                            <header class="widget__header"><h1 class="widget__title">Menu</h1></header>

                            <div class="menu-footer-menu-container">                           
                                <ul id="menu-footer-menu" class="menu">
                                    <li id="menu-item-41" class="menu__item menu__item--level-0 "><Link to='/about-us' >About Us</Link></li>
                                    {/* <li id="menu-item-40" class="menu__item menu__item--level-0 "><a href="http://buffalo-innovation.com.au/#about-us">Capabilities</a></li> */}
                                    <li id="menu-item-38" class="menu__item menu__item--level-0 "><Link to='/career' >Career</Link></li>
                                    <li id="menu-item-42" class="menu__item menu__item--level-0 "><Link to='/contact-us'>Contact Us</Link></li>
                                </ul>
                            </div>
                        </section>						
                    </div>

                    <div class="grid__column  grid__column--l-3">
                        <section id="nav_menu-3" class="widget widget_nav_menu">
                            <header class="widget__header"><h1 class="widget__title">Quick Links</h1></header>
                            <div class="menu-quick-links-container">
                                <ul id="menu-quick-links" class="menu">
                                    <li id="menu-item-270" class="menu__item menu__item--level-0 "><Link to='/projectmanagement' >Project Management</Link></li>
                                    <li id="menu-item-271" class="menu__item menu__item--level-0 "><Link to='/networkConstruction' >Mobile Network Construction</Link></li>
                                    <li id="menu-item-273" class="menu__item menu__item--level-0 "><Link to='/inBuildingSystems' >In-building Coverage</Link></li>
                                    <li id="menu-item-273" class="menu__item menu__item--level-0 "><Link to='/radioFrequencySystems' >Microwave Deployment</Link></li>
                                    <li id="menu-item-274" class="menu__item menu__item--level-0 "><Link to='/transmissionUpgrade' >Transmission Upgrade</Link></li>
                                    <li id="menu-item-274" class="menu__item menu__item--level-0 ">
                                        <a href={ExampleDoc} download="Environmental_strategy_and_objective" target='_blank' class="page-button__anchor">
                                            Environmental strategy and objective
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </section>						
                    </div>

                    <div class="grid__column  grid__column--l-3">
                        <section id="black-studio-tinymce-2" class="widget widget_black_studio_tinymce">
                            <header class="widget__header"><h1 class="widget__title">Contact</h1></header>
                            <div class="textwidget">
                                <p>
                                    Buffalo Innovation
                                    <br/>
                                    2/10 Newton St S, 
                                    <br/>
                                    Auburn NSW 2144
                                </p>
                                <p>tel: 02 8068 0169</p>
                                <p>admin@buffalo-innovation.com.au</p>
                            </div>
                        </section>						
                    </div>

                    <div class="grid__column  grid__column--l-3">
                        <section id="cascade-social-links-2" class="widget widget_social_links">
                            <header class="widget__header"><h1 class="widget__title">Connect</h1></header>
                    
                            {/*<Link
                                className='social-icon-link twitter'
                                to='/'
                                target='_blank'
                                aria-label='Twitter'
                                >
                                 <i className='fab fa-twitter-square' />
                                </Link>*/}

                            <a className='social-icon-link linkedin' target="_blank" href="https://www.linkedin.com/company/buffalo-innovation"><i className='fab fa-linkedin' /></a>          
                        </section>						
                    </div>

                    <div class="grid__column  grid__column--l-3">
                        <section id="cascade-social-links-2" class="widget widget_social_links">
                            <header class="widget__header"><h1 class="widget__title">Certification</h1></header>
                    
                            <img src="../img/ISO.png" alt="ISO Certification" width="100%"/>
      
                        </section>						
                    </div>
                </div>

                {/* <div class="footer__fine-print  footer__fine-copyright">
                    Copyright © <span itemprop="copyrightYear">2021</span> <span itemprop="copyrightHolder">CPS GLOBAL</span>
                </div>

                <div class="footer__fine-print  footer__fine-print--credit">
                    Website By <a href="https://www.magicdust.com.au/" rel="nofollow">Magicdust</a>					</div> */}
			</div>
        </div>
 
    )
}



export default Footer
