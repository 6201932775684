import React from 'react'
import { SubHeader } from "../../components/SubHeader";
import { ServiceContent } from "../../components/ServiceContent";
import about from '../../images/mobile network construction.jpg'
import { Link } from "react-router-dom";

function NetworkConstruction() {
    return (
        <div>
            <SubHeader title={"Mobile Network Construction"} />

            <ServiceContent parag3={'For 5G Upgrade projects:'} 
                            list = {[
                                'Survey for material ordering and work scope verification','Establish and bring up new transmission link for 5G',
                                'Swapping or upgrading current power system for 5G project, including Power Rack, Rectifiers, Convertor and Batteries',
                                'Arrange and install new steel work including antenna mounting pipes, stand-offs and plates',
                                'Install new 5G Air-Antenna and swap/relocate existing 4G antenna, including TMA, combiners etc.',
                                'Run new hybrid trunk / feeders as per design and termination',
                                'Install and connect internal equipment with specific carrier standard/quality',
                                'Integrate 4G and 5G into carrier network and complete call/speed test with satisfactory result',
                                'Submit acceptance document with high quality in time'
                            ]}

                            parag1={'Buffalo Innovation has in-depth telecommunication knowledge across multi-carriers / vendors. With strong understanding of Ericsson / Nokia / Huawei products and professional field work experience, we have successfully delivered 5G Upgrade and 4G Swap / RANCAP projects for Telstra / Optus / TPG / VHA.'} 
                            picture={about}
                            list1 = {[
                                        'Connect and verify transmission path for 4G',
                                        'Upgrading current power system, including Rectifiers, Batteries and DC – DC convertor',
                                        'Install and run jumpers for new internal equipment with specific carrier standard/quality',
                                        'Swap/Relocate existing 4G technologies and add new 4G frequencies as expansion',
                                        'Run new hybrid jumpers as per design and termination',
                                        'Integrate 4G and pass call/speed test with requirement',
                                        'Submit acceptance document timely with high quality'
                                    ]}
                            parag2={'For 4G Swap / RANCAP projects:'}
            /> 
              
            <div class="page-button">
                <div class="container">
                    {/* <a href="/contact-us/" class="page-button__anchor">
                    Contact us	
                    </a> */}
                    <Link to='/contact-us' class="page-button__anchor">Contact Us</Link>
                </div>
		    </div> 
        </div>
    )
}

export default NetworkConstruction
