
import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "./index.css";


// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);


export default function AutoPlay() {

  const [width, setWidth] = useState(true);
  
  const changeSlideNumer = () => {
    if (window.innerWidth <= 768) {
      setWidth(false);
    } else {
      setWidth(true);
    }
  }

  window.addEventListener("resize", changeSlideNumer);

  return (
    <>
    <Swiper 
            slidesPerView={width?3:1} 
            spaceBetween={1} 
            slidesPerGroup={1} 
            loop={true} 
            loopFillGroupWithBlank={false} 
            centeredSlides={true} 
            autoplay={{
                        "delay": 4000,
                        "disableOnInteraction": false
                        }} 
            pagination={{
                        "clickable": true
                        }} 
            navigation={true} 
            className="mySwiper" >

        <SwiperSlide>
          <div className = 'pic1 picture'> 
            <Link className="pic-link" to='/projectManagement' >
                          Project Management
            </Link>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className = 'pic2 picture'>
            <Link className="pic-link" to='/networkConstruction' >
            Mobile Network Construction
            </Link>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className = 'pic3 picture'>
            <Link className="pic-link" to='/inBuildingSystems' >
            In-building Coverage
            </Link>            
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className = 'pic4 picture'>
            <Link className="pic-link" to='/radioFrequencySystems'>
            Microwave Deployment
            </Link>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className = 'pic5 picture'>
            <Link className="pic-link" to='/transmissionUpgrade' >
            Transmission Upgrade
            </Link>
          </div>
        </SwiperSlide>
        
    </Swiper>
    </>
  )
}